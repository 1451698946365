<template>
  <div
    class="full-height d-flex justify-center align-center"
  >
    <m-loader-circular
      v-if="$wait.is('loading user')"
    />

    <template
      v-else
    >
      <v-card
        v-if="step < 4"
        tile
        max-width="500px"
        width="500px"
      >
        <div
          class="text-center full-width d-block secondary white--text"
        >
          <v-card-title
            class="d-block pt-2 pb-3"
          >
            <span
              v-translate
              translate-context="MInviteUser title"
            >
              Create user
            </span>
          </v-card-title>

          <v-card-subtitle
            class="secondary--text text--lighten-4 pb-2"
          >
            {{ companyName }}
          </v-card-subtitle>
        </div>

        <v-stepper
          v-model="step"
          class="elevation-0"
          alt-labels
        >
          <v-stepper-header
            class="backgroundAccent lighten-4"
          >
            <v-stepper-step
              :complete="step > 1"
              step="1"
            >
              <div
                v-translate
                translate-context="MInviteUser component step title"
                class="text-center"
              >
                Basic info
              </div>
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              :complete="step > 2"
              step="2"
            >
              <div
                v-translate
                translate-context="MInviteUser component step title"
                class="text-center"
              >
                Teams
              </div>
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              :complete="step > 3"
              step="3"
            >
              <div
                v-translate
                translate-context="MInviteUser component step title"
                class="text-center"
              >
                Notification
              </div>
            </v-stepper-step>
          </v-stepper-header>

          <!-- basic info -->
          <v-stepper-content
            step="1"
          >
            <div
              v-translate
              translate-context="MInviteUser component content"
              class="body-2 mb-4"
            >
              Let's start with the user's basic info.
            </div>

            <v-list
              dense
            >
              <v-form ref="userInfoForm">
                <m-user-form
                  :user="user"
                />
              </v-form>
            </v-list>
          </v-stepper-content>

          <!-- teams -->
          <v-stepper-content
            step="2"
          >
            <m-loader-circular
              v-if="$wait.is(['loading teams', 'loading teamsUsers'])"
            />

            <m-user-to-teams
              v-else
              :teams="teams"
              :project="project"
              :user="user"
              @team-selection-ok="updateTeamSelection"
            />
          </v-stepper-content>

          <!-- invitation links -->
          <v-stepper-content
            step="3"
          >
            <div
              v-translate="{ userDisplayName: user.firstName || userPlaceholder }"
              translate-context="MInviteUser component content"
              class="body-2 mb-4"
            >
              We will send %{ userDisplayName } a notification with instructions
              on how to use and access Mestamaster. You can disable this if you want.
            </div>

            <v-form
              @submit.prevent="submitRegistrationLink"
            >
              <div
                class="ml-3"
              >
                <div>
                  <v-checkbox
                    v-model="sendNotificationSelected"
                    persistent-hint
                    class="my-0 py-0"
                    @change="updateDeliverySelection"
                  >
                    <template #label>
                      <span
                        v-translate
                        translate-context="MInviteUser hint for no delivery selection"
                        class="caption"
                      >
                        Send notification to user with next steps.
                      </span>
                    </template>
                  </v-checkbox>

                  <div
                    v-if="!sendNotificationSelected"
                    class="caption deep-orange--text"
                  >
                    <v-alert
                      type="warning"
                      text
                      dense
                      border="left"
                      class="caption mb-0 mt-4"
                      icon="warning"
                    >
                      {{ noDeliverySelectionAdditionalHint }}
                    </v-alert>
                  </div>
                </div>
              </div>
            </v-form>

            <v-alert
                type="info"
                dense
                text
                border="left"
                class="caption"
              >
              <p v-if="user.role == 'manager' || user.role == 'submanager'"
                v-translate="{ userDisplayName: user.firstName || userPlaceholder }"
                class="body-2"
              > 
                We will send %{ userDisplayName } an email with a link to set a password. The link in the email is valid for one week.
                They can use Mestamaster after choosing a password.
              </p>
              <p v-else
                v-translate="{ userDisplayName: user.firstName || userPlaceholder }"
                class="body-2"
              >
                Because  %{ userDisplayName } is a worker, we will send them an SMS with a link to download the app.
                They can login with their phone number and an SMS code without needing a password.
              </p>
            </v-alert>
          </v-stepper-content>
        </v-stepper>

        <v-card-actions
          class="mt-4 py-4 px-6 backgroundAccent lighten-4"
        >
          <v-btn
            v-hotkey="{ 'esc': closeWindow }"
            color="error"
            text
            @click="closeWindow"
          >
            <span
              v-translate
              translate-context="MUsersPage cancel button"
            >
              Cancel
            </span>
          </v-btn>

          <v-spacer />

          <!-- submit basic info -->
          <template
            v-if="step === 1"
          >
            <v-btn
              :disabled="$wait.is('saving user')"
              :loading="$wait.is('saving user')"
              class="px-4"
              color="secondary"
              @click="submitUserInfo"
            >
              <span
                v-translate
                translate-context="MInviteUser component save basic info button"
              >
                Save
              </span>
            </v-btn>
          </template>

          <!-- submit team selections -->
          <template
            v-if="step === 2"
          >
            <v-btn
              :disabled="!teamSelectionOk"
              class="px-4"
              color="secondary"
              @click="() => nextStep()"
            >
              <span
                v-translate
                translate-context="MInviteUser component save teams button"
              >
                Save
              </span>
            </v-btn>
          </template>

          <!-- submit invitations -->
          <template
            v-if="step === 3"
          >
            <v-btn
              v-if="!sendNotificationSelected"
              color="secondary"
              class="px-4"
              @click="() => nextStep()"
            >
              <span
                v-translate
                translate-context="MInviteUser component ready with invitations button"
              >
                Ready
              </span>
            </v-btn>

            <v-btn
              v-else
              :loading="$wait.is('sending invitations')"
              :disabled="$wait.is('sending invitations')"
              :dark="!$wait.is('sending invitations')"
              color="secondary"
              class="px-4"
              @click="sendInvitations"
            >
              <span
                v-translate
                translate-context="MInviteUser component send invitations button"
              >
                Send
              </span>
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>

      <div
        v-if="step === 4"
      >
        <v-alert
          icon="thumb_up"
          color="secondary"
          text
          prominent
          border="top"
          class="caption"
          max-width="400px"
        >
          <v-row align="center">
            <v-col class="grow">
              <div
                v-translate
                translate-context="MInviteUser component alert"
                class="ml-2"
              >
                User saved. You can now navigate elsewhere through the menu.
              </div>
            </v-col>
            <v-col class="shrink">
              <v-btn
                small
                depressed
                color="secondary"
                @click="closeWindow"
                v-translate
                translate-context="MInviteUsers dialogue button"
              >
                Open users
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import cloneDeep from 'lodash.clonedeep';
  import { mapWaitingActions } from 'vue-wait';
  import MUserForm from '@/components/MUserForm';
  import MUserToTeams from '@/components/MUserToTeams';

  export default {
    components: {
      MUserToTeams,
      MUserForm,
    },

    props: {
      userId: {
        type: Number,
        default: null,
      },
    },

    data: () => ({
      user: {},
      deliveryTypes: [],
      teamSelectionOk: false,
      sendNotificationSelected: true,
    }),

    computed: {
      ...mapGetters({
        teams: 'project/teams/teams',
        project: 'project/project/project',
        preloadedUser: 'users/user',
        isMobile: 'utils/isMobile',
        companyById: 'companies/companyById',
      }),

      isNew() {
        return !this.user.id;
      },

      step() {
        return parseInt(this.$route.query.step, 10) || 1;
      },

      userPlaceholder() {
        return this.$pgettext('MInviteUser component placeholder', 'User');
      },

      privacyStatementLink() {
        return `
          <a
            target="_blank"
            href="${this.$pgettext('MInviteUser component alert privacy statement link URL', 'https://mestamaster.fi/en/tietosuojaseloste')}"
          >${this.$pgettext('MInviteUser component alert privacy statement link text (possessive)', 'privacy statement')}</a>`;
      },

      companyName() {
        const company = this.companyById[this.project.companyId];
        if (!company) return '';
        return company.name;
      },

      noDeliverySelectionAdditionalHint() {
        const hint = this.$gettextInterpolate(
          this.$pgettext(
            'MInviteUser select no delivery hint',
            'You chose not to deliver a notification to the user. Please make sure to inform them about the invitation, and instruct them how to use the service.',
          ),
          {
            firstName: this.user.firstName,
          },
        );

        return hint;
      },
    },

    watch: {
      step: {
        immediate: true,
        async handler(step) {
          if (step === 2) {
            await Promise.all([
              this.loadTeams(),
              this.loadTeamsUsers(),
            ]);
          }
        },
      },

      preloadedUser(user) {
        this.user = cloneDeep(user);
      },

      deliveryTypes(types) {
        if (types.length > 0) {
          this.sendNotificationSelected = false;
        }
      },
    },

    created() {
      if (this.userId) this.loadUser({ userId: this.userId });

      /**
       * Defaults to the same company to which the current company belongs to.
       */
      if (!this.user.companyId) {
        this.user.companyId = this.project.companyId;
      }
    },

    beforeRouteLeave(to, from, next) {
      /**
       * If user is not yet created, we can safely move away.
       */
      if (!this.userId || this.step >= 4) {
        next(true);
        return;
      }

      const msg = this.$pgettext(
        'MInviteUser confirm that user wants to cancel creating a new user',
        'If you need to leave now, please understand that some critical user information might be missing.',
      );

      const confirmed =  confirm(msg); // eslint-disable-line
      next(confirmed);
    },

    methods: {
      ...mapActions({
        sendInvitationLinks: 'users/sendInvitationLinks',
      }),

      ...mapWaitingActions('users', {
        loadUser: 'loading user',
        createUser: 'saving user',
      }),

      ...mapWaitingActions('project/teams', {
        loadTeams: 'loading teams',
      }),

      ...mapWaitingActions('project/teamsUsers', {
        loadTeamsUsers: 'loading teamsUsers',
      }),

      closeWindow() {
        this.$router.replace({
          name: 'project-users',
        });
      },

      nextStep(userId = null) {
        this.$router.push({
          name: 'invite-user',
          params: {
            userId: userId || this.userId,
          },
          query: {
            step: this.step + 1,
          },
        });
      },

      /**
       * Note that if a user is already created,
       * this method just goes to step 2.
       */
      async submitUserInfo() {
        if (this.isNew) {
          const valid = this.$refs.userInfoForm.validate();
          if (!valid) return;

          /**
           * Don't send the companyId in params as it's unpermitted anyway
           */
          const userParams = { ...this.user };
          delete userParams.companyId;

          const user = await this.createUser({
            companyId: this.user.companyId,
            params: {
              user: userParams,
            },
          });

          if (!user) return;

          this.user = cloneDeep(user);
        }

        this.nextStep(this.user.id);
      },

      async sendInvitations() {
        if (!this.sendNotificationSelected) return;

        this.$wait.start('sending invitations');

        const params = {
          userId: this.user.id,
          payload: {},
        };

        await this.sendInvitationLinks(params);
        this.$wait.end('sending invitations');
        this.nextStep();
      },

      updateTeamSelection(ok) {
        this.teamSelectionOk = ok;
      },

      updateDeliverySelection(selected) {
        if (selected) this.deliveryTypes = [];
      },
    },
  };
</script>
