<template>
  <div>
    <div
      v-translate="{
        firstName,
        projectPid: project.pid,
      }"
      translate-context="MUserToTeams component content"
      class="body-2"
    >
      %{ firstName } can participate in the project <b>%{ projectPid }</b>
      after they've been added into the correct teams.
    </div>

    <v-subheader
      v-translate
      translate-context="MUserToTeams component content"
    >
      Which teams would you like to add them into?
    </v-subheader>

    <div
      class="ml-3"
    >
      <div
        v-for="(team, i) in teams"
        :key="i"
      >
        <v-checkbox
          :input-value="isAssignedToTeam({ team, user })"
          :disabled="$wait.is([`team${team.id}-user-${user.id}`, 'deleting all'])"
          hide-details
          class="my-0 py-0"
          @change="checked => onCheckboxChange({ checked, team, user })"
        >
          <template v-slot:label>
            <div
              class="d-flex align-center relative"
            >
              <div class="caption">
                {{ team.name }}
              </div>

              <div
                v-if="$wait.is([`team${team.id}-user-${user.id}`, 'deleting all'])"
                :style="{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  left: 0,
                }"
              >
                <m-loader-circular
                  :size="12"
                  :width="1"
                  text=""
                  style="margin: 0 !important;"
                />
              </div>
            </div>
          </template>
        </v-checkbox>
      </div>

      <v-divider
        class="my-4"
      />

      <div>
        <v-checkbox
          v-model="noTeamsSelected"
          persistent-hint
          :hint="noTeamSelectionHint"
          class="my-0 py-0"
          @change="checked => onCheckboxChange({ checked })"
        >
          <template #label>
            <span
              v-translate
              translate-context="MUserToTeams hint for no team selection"
              class="caption"
            >
              No team selection
            </span>
          </template>
        </v-checkbox>

        <div
          v-if="noTeamsSelected"
          class="caption deep-orange--text"
        >
          <v-alert
            type="warning"
            text
            dense
            border="left"
            class="caption mb-0 mt-4"
            icon="warning"
          >
            {{ noTeamSelectionAdditionalHint }}
          </v-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    props: {
      teams: {
        type: Array,
        required: true,
      },

      project: {
        type: Object,
        required: true,
      },

      user: {
        type: Object,
        required: true,
      },
    },

    data: () => ({
      noTeamsSelected: false,
    }),

    computed: {
      ...mapGetters({
        isAssignedToTeam: 'project/teamsUsers/isAssignedToTeam',
        teamUser: 'project/teamsUsers/teamUser',
        __teamsUsersByUser__: 'project/teamsUsers/teamsUsersByUser',
      }),

      teamsUsersByUser() {
        return this.__teamsUsersByUser__(this.user.id);
      },

      firstName() {
        return this.user.firstName || this.$pgettext('MUserToTeams component content', 'User');
      },

      noTeamSelectionHint() {
        const hint = this.$gettextInterpolate(
          this.$pgettext(
            'UserToTeams select no teams',
            'This selection adds %{ firstName } to Mestamaster but not in the project. You can change the selection later from the Users page.',
          ),
          {
            firstName: this.user.firstName,
          },
        );

        return hint;
      },

      noTeamSelectionAdditionalHint() {
        const hint = this.$gettextInterpolate(
          this.$pgettext(
            'UserToTeams select no teams',
            'By selecting this, you understand that %{ firstName } cannot participate in project %{ pid }.',
          ),
          {
            firstName: this.user.firstName,
            pid: this.project.pid,
          },
        );

        return hint;
      },
    },

    watch: {
      teamsUsersByUser: {
        immediate: true,
        handler(newVal) {
          const ok = (this.noTeamsSelected && newVal.length <= 0) || newVal.length > 0;
          this.$emit('team-selection-ok', ok);
        },
      },

      noTeamsSelected: {
        immediate: true,
        handler(selected) {
          const ok = (selected && this.teamsUsersByUser.length <= 0)
            || this.teamsUsersByUser.length > 0;
          this.$emit('team-selection-ok', ok);
        },
      },
    },

    methods: {
      ...mapActions({
        createTeamUser: 'project/teamsUsers/createTeamUser',
        destroyTeamUser: 'project/teamsUsers/destroyTeamUser',
      }),

      async onCheckboxChange({ checked, team = null, user = null }) {
        /**
         * Explicit no team selection
         */
        if (checked && !team && !user) {
          this.$wait.start('deleting all');

          const teamsUsers = this.teamsUsersByUser;
          const promises = teamsUsers.map(tu => (
            this.destroyTeamUser({ projectId: this.project.id, id: tu.id })
          ));
          await Promise.all(promises);

          this.$wait.end('deleting all');
        }

        /**
         * Select this team
         */
        if (checked && team && user) {
          this.noTeamsSelected = false;

          const loadingId = `team${team.id}-user-${user.id}`;
          this.$wait.start(loadingId);

          const params = {
            projectId: this.project.id,
            payload: {
              teamUser: {
                teamId: team.id,
                userId: user.id,
              },
            },
          };

          await this.createTeamUser(params);

          this.$wait.end(loadingId);
        }

        /**
         * Un-select this team
         */
        if (!checked && team && user) {
          const loadingId = `team${team.id}-user-${user.id}`;
          this.$wait.start(loadingId);

          const teamUser = this.teamUser({ team, user });
          await this.destroyTeamUser({ projectId: this.project.id, id: teamUser.id });

          this.$wait.end(loadingId);
        }
      },
    },
  };
</script>
