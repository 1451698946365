var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"translate",rawName:"v-translate",value:({
      firstName: _vm.firstName,
      projectPid: _vm.project.pid,
    }),expression:"{\n      firstName,\n      projectPid: project.pid,\n    }"}],staticClass:"body-2",attrs:{"translate-context":"MUserToTeams component content"}},[_vm._v(" %{ firstName } can participate in the project "),_c('b',[_vm._v("%{ projectPid }")]),_vm._v(" after they've been added into the correct teams. ")]),_c('v-subheader',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MUserToTeams component content"}},[_vm._v(" Which teams would you like to add them into? ")]),_c('div',{staticClass:"ml-3"},[_vm._l((_vm.teams),function(team,i){return _c('div',{key:i},[_c('v-checkbox',{staticClass:"my-0 py-0",attrs:{"input-value":_vm.isAssignedToTeam({ team: team, user: _vm.user }),"disabled":_vm.$wait.is([("team" + (team.id) + "-user-" + (_vm.user.id)), 'deleting all']),"hide-details":""},on:{"change":function (checked) { return _vm.onCheckboxChange({ checked: checked, team: team, user: _vm.user }); }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex align-center relative"},[_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(team.name)+" ")]),(_vm.$wait.is([("team" + (team.id) + "-user-" + (_vm.user.id)), 'deleting all']))?_c('div',{style:({
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: 0,
              })},[_c('m-loader-circular',{staticStyle:{"margin":"0 !important"},attrs:{"size":12,"width":1,"text":""}})],1):_vm._e()])]},proxy:true}],null,true)})],1)}),_c('v-divider',{staticClass:"my-4"}),_c('div',[_c('v-checkbox',{staticClass:"my-0 py-0",attrs:{"persistent-hint":"","hint":_vm.noTeamSelectionHint},on:{"change":function (checked) { return _vm.onCheckboxChange({ checked: checked }); }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"caption",attrs:{"translate-context":"MUserToTeams hint for no team selection"}},[_vm._v(" No team selection ")])]},proxy:true}]),model:{value:(_vm.noTeamsSelected),callback:function ($$v) {_vm.noTeamsSelected=$$v},expression:"noTeamsSelected"}}),(_vm.noTeamsSelected)?_c('div',{staticClass:"caption deep-orange--text"},[_c('v-alert',{staticClass:"caption mb-0 mt-4",attrs:{"type":"warning","text":"","dense":"","border":"left","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.noTeamSelectionAdditionalHint)+" ")])],1):_vm._e()],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }